<template>
  <div>
    <p class="mb-6">Ajuste General</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" v-if="id_puesto == '-1'">
            <select-puesto
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
               :filtros="{id_reporte:46}"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectAlmacen
              :disabled="filtros.id_puesto.length > 1"
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_almacen" 
              
              :filtro="{ id_puesto: filtros.id_puesto[0] } "
              :add="false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <DosFechasVue @getFechas="getFechas" :fechas="[filtros.desde, filtros.hasta]" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectEstado
              v-model="filtros.estado"
              :dense="true"
              :outlined="true"
              :add="false"
              :padre="18"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectMotivo   :dense="true" :clearable="true" :outlined="true" :add="false" v-model="filtros.id_motivo" />
          </v-col>
          
          <v-col cols="12" sm="6" md="4">
            <v-text-field
            hide-details="auto"
              class="mt-0 pt-0"
              outlined
              dense
              label="Numero Documento"
              v-model="filtros.numero_documento"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
            hide-details="auto"
              class="mt-0 pt-0"
              outlined
              dense
              label="Nombre/Descripcion/referencia"
              v-model="filtros.nombre_producto"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
        ></TablaSimpleReporte>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue'  
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import DosFechasVue from '@/components/DosFechas.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectMotivo from '@/views/sistema/puesto/inventario/ajuste/componentes/SelectMotivo.vue'

// demos

export default {
  props: {
    id_puesto: {
      type: String,
      default: -1,
    },
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
    DosFechasVue,
    SelectEstado,
    SelectMotivo
  },
  watch: {
    userCliente: function (old, news) {
      if (old != news) {
        this.filtros.id_sub_categoria = null
      }
    },
    'filtros.id_puesto': function (old, news) {
      if (old != news) {
         
          this.filtros.id_almacen = null
       
      }
    },
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  setup(props) {
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const nombreReporte = ref(`Reporte Ajuste`)
    const getFechas = datos => {
      filtros.value.desde = datos.desde
      filtros.value.hasta = datos.hasta
    }

    const cabecera = ref([
      { text: 'Id', value: 'id', format: { small: true } },
      { text: 'numeroDocumento', value: 'numero_documento', format: { small: true } }, 
      { text: 'Descripcion', value: 'descripcion', format: { small: true } },
      { text: 'Estado', value: 'estado_json.descripcion', format: { small: true } },
      { text: 'fecha', value: 'fecha_fabricacion', format: { date: true, small: true } },
      { text: 'fecha Creacion', value: 'fecha', format: { dateTime: true, small: true } },
      
      { text: 'Empleado', value: 'empleado_crea.nombre_completo', format: { small: true } },
      { text: 'Empleado Anula', value: 'empleado_anula.nombre_completo', format: { small: true } },
      { text: 'Area', value: 'puesto.nombre', format: { small: true } },
      { text: 'Almacen', value: 'almacen_origen.descripcion', format: { small: true } },
      { text: 'Motivo', value: 'motivo.descripcion', format: { small: true } }, 
      { text: 'Items', value: 'items', format: { small: true } }, 
      { text: 'Total', value: 'total', format: { small: true } }, 
    ])

    const filtrosInit = {
      id_categoria: null,
      id_sub_categoria: null,
      id_puesto: [],
      id_almacen: null,
      id_producto_tipo: null,
      numero_documento: '',
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
      estado:null,
      nombre_producto :'',
      id_motivo: null,
    }

    const datos = ref([])
    const filtros = ref(JSON.parse(JSON.stringify(filtrosInit)))

    const cargando = ref(false)

    const LimpiarFiltros = () => {
      filtros = JSON.parse(JSON.stringify(filtrosInit))
    }

    const buscarDatos = () => {
      cargando.value = true
      AjusteServices.reporteListarAjuste({
        ...filtros.value,
        id_puesto: filtros.value.id_puesto?.join(','),
      })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Reporte Ajuste ${filtros.value.desde}-${filtros.value.hasta}`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      LimpiarFiltros,
      getFechas
    }
  },
}
</script>
